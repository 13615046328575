<template>
  <div class="body-box" v-track="{ event: 'browse', route: $route, isButton: false }">
    <div style="margin-bottom: 10px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/Empowerment/pledge/houseProperty/rapidValuation' }">快速估价</el-breadcrumb-item>
        <el-breadcrumb-item> 地图估价</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main-content">
      <div class="city-selcet">
        <Select v-model="city" @on-change="cityChange" filterable style="width:112px;box-shadow: 0px 3px 8px 1px rgba(38,38,38,0.1);
                border-radius: 4px 4px 4px 4px;">
          <Option v-for="item in cityList" :value="item.cityCode" :label="item.cityFullName" :key="item.cityCode">
            <span>{{ item.cityFullName }} </span>
          </Option>
        </Select>
      </div>
      <div class="input-box">
        <!-- <ComInput ></ComInput> -->
        <el-select v-model="inputLpName" v-track="{ event: 'click', route: $route, isButton: false, log: '搜索小区' }"
          @change="inputLpNameChange" no-data-text="找不到你想要的小区" style="width: 100%;" size="small" filterable remote
          :loading="loading" :remote-method="remoteMethod" placeholder="输入小区名称搜索">
          <el-option v-for="item in communityList" :key="item.value" :label="item.label" :value="item.value">
            <!-- <span style="float: left">{{ item.communityName }}</span> -->
            <div class="el-box">
              <span class="parentheses" v-html="item.lpNameHighlight">
              </span>
              <span style=" color: #8492a6; font-size: 13px">{{ item.countyFullName }}</span>
            </div>

          </el-option>
        </el-select>
      </div>
      <div class="contro-map">
        <ControlMap @mapTypeChange="mapChange"></ControlMap>
      </div>
      <div class="Bmap" id="Bmap"></div>
      <div class="demo-spin-container" v-if="spinShow">
        <Spin fix></Spin>
      </div>
    </div>
  </div>
</template>

<script>
import { lngLatCommunityList, communitySelectCondition, countyLocation } from '@/api/pledge'
import ComInput from '@/views/pledge/components/ComInput.vue'
import ControlMap from '@/views/pledge/components/ControlMap.vue'
import {
  data_user_operation_log_in
} from '@/api/operationLog.js'
import dataReports from "@/mixins/dataReports";
import cityLaction from './cityLaction.js'

import {
  debounce
} from '@/utils/index.js'
export default {
  components: {
    // ComInput,
    ControlMap
  },
  mixins: [dataReports],

  data() {
    return {
      CityLaction: cityLaction,//城市市政府对应经纬度
      loading: false,
      noDataText: '找不到你想要的小区',
      mapAllowClick: true,// 小区是否允许点击
      searchloading: false,
      inputLpName: '',
      communityName: '',
      city: '',
      spinShow: false,//加载动画
      cityObj: JSON.parse(sessionStorage.getItem('cityObj')),
      cityList: JSON.parse(sessionStorage.getItem('cityList')),
      map: null,
      mapData: {//初始数据1
        timer: null,
        zoom: 17,
        center: {
          lng: 113.335593,
          lat: 23.119767,
        },
      },
      foShanCenter: {//佛山市政府经纬度
        lng: 113.128212,
        lat: 23.027216
      },
      dongGuanCenter: {//东莞市政府经纬度
        lng: 113.758184,
        lat: 23.027314
      },
      guangzhouCenter: {//广州市 中和广场经纬度
        lng: 113.335593,
        lat: 23.119767,
      },
      communityList: [//小区列表

      ],
      showScope: {
        SouthWest: {
          lng: 113.02996647899766,
          lat: 23.011918008667195,
        },
        NorthEast: {
          lng: 113.51289552100229,
          lat: 23.248279812734918,
        },
        zoom: 17,
      },
    };
  },
  watch: {


  },
  created() {

  },
  mounted() {
    let { cityCode } = this.cityObj
    this.city = cityCode
    this.mapData.center = this.CityLaction[this.cityObj.cityFullName]//根据城市名字匹配市政府经纬度

    let params = this.$route.params
    if (params) {
      if (params.center) {
        if (params.center.lat) {

          this.mapData.center = params.center
        }
      }
    }
    this.loadMap()
  },
  methods: {
    cityChange(cityCode) {
      this.cityList.map(item => {
        if (cityCode == item.cityCode) {
          this.cityObj = item
          sessionStorage.setItem('cityObj', JSON.stringify(item))
          this.$store.commit("changeCityObj", item)
        }
      })

      this.mapData.center = this.CityLaction[this.cityObj.cityFullName]
      // this.map.centerAndZoom(this.mapData.center, this.mapData.zoom)
      this.loadMap()

    },
    mapChange(type) {
      if (type == 'NORMAL') {
        this.map.setMapType(BMAP_NORMAL_MAP);
      } else {
        this.map.setMapType(BMAP_SATELLITE_MAP);

      }
    },
    inputLpNameChange(value) {
      let obj = {}
      this.communityList.map(item => {
        if (value == item.value) {
          obj = item
        }
      })
      this.communityName = obj.communityName
      const myPoint = new BMap.Point(
        obj.lpLngBaidu,
        obj.lpLatBaidu
      );
      // that.map.centerAndZoom(myPoint, 7);
      // 初始化地图， 设置中心点坐标和地图级别
      this.map.centerAndZoom(myPoint, 17);
    },
    remoteMethod(query) {
      // if (query == '') {
      //   this.getSelectHistory()
      // } else {
      //   this.showHistory = false

      // }
      //  this.communityList = []
      this.searchloading = false;
      if (query.length < 2) {
        return
      }
      if (query !== '') {
        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.loading = true;

        this.timer = setTimeout(async () => {
          this.searchloading = true;

          // this.loading = true;
          let { cityCode } = this.cityObj
          let params = {
            condition: query,
            cityCode
          }
          let data = await communitySelectCondition(params)
          this.loading = false;
          this.searchloading = false;
          if (data.length == 0) {
            this.noDataText = '找不到你想要的小区'
            this.communityList = []
            this.inputLpName = ''
          }
          data.map(item => {
            // item.value = item.communityName
            item.value = item.lpCode
            item.label = item.communityName
            return item
          })
          this.communityList = data


        }, 1000)
      }
    },
    loadMap() {//初始化地图
      let that = this;
      that.map = new BMap.Map("Bmap");
      const myPoint = new BMap.Point(
        that.mapData.center.lng,
        that.mapData.center.lat
      );
      // that.map.centerAndZoom(myPoint, 7);
      // 初始化地图， 设置中心点坐标和地图级别
      that.map.centerAndZoom(myPoint, that.mapData.zoom);
      that.map.enableScrollWheelZoom(true);
      this.map.addEventListener("moveend", this.moveend);
      this.map.addEventListener("zoomend", this.zoomend);
      this.map.addEventListener("dragend", this.dragend);
      this.map.addEventListener("dragstart", this.dragstart);
      // that.map.moveend()
      setTimeout(() => {
        this.getBounds(that.map);
      }, 100);



    },
    dragstart() {//拖拽开始
      this.mapAllowClick = false
    },
    dragend() {//拖拽结束
      setTimeout(() => {
        this.mapAllowClick = true
      }, 300);


    },
    moveend() {//移动结束
      let zoom = this.map.getZoom(); // 获取缩放级别

      if (zoom <= 16) {
        // this.map.clearOverlays();
        this.getAdministrativeRegionData()
      } else {
        setTimeout(() => {
          this.getBounds(this.map);
        }, 100);

      }
      //  this.getBounds(this.map);
    },
    zoomend() {//缩放结束
      let zoom = this.map.getZoom(); // 获取缩放级别

      this.map.clearOverlays();
      if (zoom <= 16) {
        // this.map.clearOverlays();
        this.getAdministrativeRegionData()
      } else {
        this.getBounds(this.map);

      }

    },

    async getAdministrativeRegionData() {//获取行政区数据
      let list = [

      ]
      let params = {
        cityCode: this.cityObj.cityCode
      }
      let data = await countyLocation(params)
      list = data
      //  countyLocation
      this.setAdminMark(list)
    },

    setAdminMark(list) {//绘制行政区地图
      let that = this
      this.map.clearOverlays();
      list.forEach(item => {
        let myPoint = new BMap.Point(item.lng, item.lat
        );
        let opts = {
          position: myPoint,
          offset: new BMap.Size(-20, -20)
        }
        let info = new BMap.Label(item.districtName, opts)
        info.setContent(
          `<div class="admin-mark-info-box">
            ${item.districtName}
          </div>`
        );
        info.setStyle({
          border: "none",
          borderRadius: "5px",
          backgroundColor: 'transparent',

        });
        info.addEventListener("click", () => {
          // that.goreidValuation(item)
          that.clickAdminMark(item)
        });
        //鼠标hover时也变颜色
        info.addEventListener("mouseover", () => {
          if (item.isHover) {
            return
          }
          info.setStyle({
            zIndex: 99999999999999
          });
          item.isHover = true
        });
        //鼠标离开时还原
        info.addEventListener("mouseout", () => {
          info.setStyle({
            zIndex: 10
          });
          item.isHover = false
        });
        this.map.addOverlay(info);
        // this.map.addOverlay(newInfo);
      });
    },
    clickAdminMark(item) {//点击行政区
      this.spinShow = true

      this.map.clearOverlays();
      const myPoint = new BMap.Point(
        item.lng,
        item.lat,
      );
      // that.map.centerAndZoom(myPoint, 7);
      // 初始化地图， 设置中心点坐标和地图级别
      this.map.centerAndZoom(myPoint, 17);

    },
    //获取地图数据
    getBounds(baidumap) {
      if (baidumap) {
        const Bounds = baidumap.getBounds();
        this.showScope.SouthWest = Bounds.getSouthWest();
        this.showScope.NorthEast = Bounds.getNorthEast();
        let point = Bounds.getCenter();
        // lngLatCommunityList

        //获取
        this.getCommunityList()
      }
    },
    async getCommunityList() {
      // this.$Spin.show()

      let { cityCode, provinceCode } = this.cityObj
      let params = {
        cityCode,
        provinceCode,
        isBaidu: true,
        points: []
      }
      let NorthEast = this.showScope.NorthEast
      let SouthWest = this.showScope.SouthWest
      let NE = {
        lng: NorthEast.lng.toString(),
        lat: NorthEast.lat.toString()
      }
      let SW = {
        lng: SouthWest.lng.toString(),
        lat: SouthWest.lat.toString()
      }
      params.points.push(NE)
      params.points.push(SW)
      lngLatCommunityList(params).then(res => {
        this.setMark(res)
        this.spinShow = false


      }).catch(err => {
        // this.spinShow = false
      })

    },
    setMark(list) {
      let that = this
      this.map.clearOverlays();
      const debouncedGetCommunityList = debounce(this.getCommunityList, 500);
      list.forEach(item => {
        let myPoint = new BMap.Point(item.center.lpLngBaidu, item.center.lpLatBaidu
        );
        let opts = {
          position: myPoint,
          offset: new BMap.Size(-20, -20)
        }
        let info = new BMap.Label(item.communityName, opts)
        info.setContent(
          `<div class="map-info-box">
            ${item.communityName}
          </div>`
        );
        info.setStyle({
          border: "none",
          // color: "#ffffff",
          borderRadius: "5px",
          backgroundColor: 'transparent',
          // borderColor: "#018BFF",
          // backgroundColor: '#018BFF',
          // fontSize: "18px",
          // height: "40px",
          // lineHeight: "36px",
          // fontFamily: " PingFangSC-Medium-, PingFangSC-Medium;",
          // paddingLeft: '10px',
          // paddingRight: '10px',
          // cursor: 'pointer'


        });
        if (this.communityName == item.communityName) {
          info.setContent(
            `<div class="map-info-box map-info-yellow">
            ${item.communityName}
          </div>`
          );
          info.setStyle({
            borderColor: "#FF8000",
            backgroundColor: '#FF8000',
          });
          info.setZIndex(9999999999999)
        }
        info.addEventListener("click", () => {
          that.goreidValuation(item)
        });
        //鼠标hover时也变颜色
        info.addEventListener("mouseover", () => {
          if (item.isHover) {
            return
          }

          info.setStyle({

            zIndex: 99999999999999
          });
          item.isHover = true
        });
        //鼠标离开时还原
        info.addEventListener("mouseout", () => {
          info.setStyle({
            zIndex: 10
          });
          item.isHover = false
        });
        this.map.addOverlay(info);
        // this.map.addOverlay(newInfo);
      });

    },
    goreidValuation(item) {
      if (!this.mapAllowClick) {//表示是拖拽地图
        return
      }
      let that = this
      let _params = {
        event: 'click',
        isButton: false,
        log: JSON.stringify(item.communityName),
        os: navigator.userAgent,
        platform: '数据平台PC',
        route: that.$route.path,
        title: that.$route.meta.title,
        token: that.$store.state.token ? that.$store.state
          .token : '',
        user_id: that.$store.state.userId ? that.$store
          .state
          .userId : '',
      }
      data_user_operation_log_in(_params)
      let params = {
        center: item.center,
        cityCode: item.cityCode,
        communityName: item.communityName,
        lpCode: item.lpCode,
      }
      this.$router.push({
        name: '快速估价',
        params: params
      })
    },
  }
};
</script>

<style scoped lang="scss">
.parentheses {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  // display: -webkit-box;
}

.el-box {
  display: flex;
  justify-content: space-between;
  // width: 420px;
}

.body-box {
  background: #EFF1F6;
  height: 100%;
  min-height: 100%;
  padding: 24px;

  .main-content {
    background: #FFFFFF;
    // height: 100%;
    min-height: 100%;
    min-width: 1400px;
    border-radius: 10px 10px 10px 10px;
    padding: 24px;

    .city-selcet {

      position: absolute;
      left: 68px;
      top: 80px;
      width: 420px;

      z-index: 10;
    }

    .input-box {
      position: absolute;
      left: 68px;
      top: 120px;
      width: 420px;

      z-index: 9;

    }

    .contro-map {
      position: absolute;
      right: 100px;
      bottom: 70px;
    }
  }


}

#Bmap {
  height: 770px;
  // min-width: 748px;
  width: 100%;
  background: #eee;
}
</style>

<style>
.map-info-box {
  /* position: absolute; */
  position: relative;
  /* display: inline; */
  cursor: pointer;
  background-color: rgb(1, 139, 255);
  border: 1px solid rgb(1, 139, 255);
  padding: 1px 10px;
  white-space: nowrap;
  font: 18px / 36px arial, sans-serif;
  z-index: 13375666;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  height: 40px;
  user-select: none;

  /* left: 121px;
  top: 251px; */
  &:hover {
    background-color: rgb(255, 128, 0);
    border: 1px solid rgb(255, 128, 0);

    &:after {
      border-top-color: rgb(255, 128, 0);
    }
  }

}
.map-info-box:hover{
 background-color: rgb(255, 128, 0);
    border: 1px solid rgb(255, 128, 0);
}
.map-info-box:hover:after{

  border-top: 9px solid rgb(255, 128, 0);

}
.map-info-box:after {
  position: absolute;
  background: transparent;
  top: 100%;
  left: calc(50% - 11px);
  width: 25px;
  height: 30px;
  content: '';
  box-sizing: border-box;
  border-top: 9px solid #018BFF;
  border-left: 14px solid transparent;
  border-right: 11px solid transparent;
  background-position: -20px bottom;
  background-origin: border-box;

}

.map-info-yellow {
  background-color: rgb(255, 128, 0);
  border: 1px solid rgb(255, 128, 0);
}

.map-info-yellow:after {
  border-top: 9px solid #FF8000;
}


.admin-mark-info-box {
  width: 84px;
  height: 84px;
  line-height: 1;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  font-family: PingFangSC-Semibold;
  color: #fff;
  background: #018BFF;

  /* &:hover {
    width: 110px;
    height: 110px;
    background-color: rgb(255, 128, 0);
    border: 1px solid rgb(255, 128, 0);
  } */
}
.admin-mark-info-box:hover{
  width: 110px;
    height: 110px;
    background-color: rgb(255, 128, 0);
    border: 1px solid rgb(255, 128, 0);

}
</style>
