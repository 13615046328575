<template>

  <div class="img-box" @mouseover="mouseOver" @mouseleave="mouseLeave">
    <div class="base-box" v-show="showMoreImg">
      <div class="base_img1" @click="changeMap('SATELLITE')">
        <div class="text">卫星</div>
      </div>
      <div class="base_img2" @click="changeMap('NORMAL')">
        <div class="text">地图</div>

      </div>
    </div>
    <div class="one-base-box"  v-show="!showMoreImg">
        <div class="base">
      <div class="text">地图</div>
    </div>
    </div>

  </div>

</template>

<script>
export default {
  data() {
    return {
      showMoreImg: false,

    };
  },
  created() {

  },
  mounted() {

  },
  methods: {
    mouseOver() {
      this.showMoreImg = true
    },
    mouseLeave() {
      this.showMoreImg = false

    },
     changeMap(type) {
        this.$emit('mapTypeChange',type)
    },
  }
};
</script>

<style scoped lang="scss">

  .img-box {
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 9;

    .base-box {
      width: 206px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 76px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;

      .base_img1 {
        height: 60px;
        width: 86px;
        background-image: url(../../../assets/image/map/map_img.png);
        background-position: 0 -61px;
        border: 1px solid #ffffff;
        cursor: pointer;

        &:hover {

          border: 1px solid #0077FF
        }

        &:hover .text {
          background: #0077FF;
          color: #fff;
        }

        .text {
          position: relative;
          display: inline-block;
          bottom: -36px;
          padding: 2px;
          right: -40px;
          color: #0077FF;
          text-align: center;
          width: 40px;
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          background: #FFFFFF;
          border-radius: 4px 4px 4px 4px;

        }

      }

      .base_img2 {
        height: 60px;
        width: 86px;
        background-image: url(../../../assets/image/map/map_img.png);
        background-position: 0 0px;
        border: 1px solid #ffffff;
        cursor: pointer;


        &:hover {

          border: 1px solid #0077FF
        }

        &:hover .text {
          background: #0077FF;
          color: #fff;
        }

        .text {
          position: relative;
          display: inline-block;
          bottom: -36px;
          padding: 2px;
          right: -40px;
          color: #0077FF;
          text-align: center;
          width: 40px;
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          background: #FFFFFF;
          border-radius: 4px 4px 4px 4px;

        }

      }


    }
.one-base-box{
      width: 102px;
 display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 77px;
      background: #FFFFFF;
      border-radius: 4px;

}
    .base {
      height: 60px;
      width: 86px;
      background-image: url(../../../assets/image/map/map_img.png);
      background-position: 0 0px;
      border: 1px solidrgba(200, 200, 200, 0);
      // margin-bottom: 17px;
      // margin-right: 15px;

      .text {
        position: relative;
        display: inline-block;
        bottom: -36px;
        padding: 2px;
        right: -40px;
        color: #0077FF;
        text-align: center;
        width: 40px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

      }

    }
  }

</style>
